/* src/App.js */
import React, { useEffect, useState } from 'react'
import { Amplify, API, graphqlOperation } from 'aws-amplify'
import { createTodo } from './graphql/mutations'
import { deleteTodo } from './graphql/mutations'
import { listTodos } from './graphql/queries'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Container, Form, Button, ListGroup, DropdownButton, Dropdown, Row, Col } from "react-bootstrap";

import awsExports from "./aws-exports";
Amplify.configure(awsExports);
/* amplify mock api*/

const initialState = { name: '', category: 'Einkaufen' }


const App = () => {
  const [formState, setFormState] = useState(initialState)
  const [todos, setTodos] = useState([])

  useEffect(() => {
    fetchTodos()

  }, [])

  function setInput(key, value) {
    setFormState(prevValues => {
      return { ...prevValues,[key]: value}})
  }


  async function fetchTodos() {
    try {
      const todoData = await API.graphql(graphqlOperation(listTodos))
      const todos = todoData.data.listTodos.items.sort((a,b) => a.createdAt < b.createdAt)
      //console.log(todos)
      setTodos(todos)
    } catch (err) { console.log('error fetching todos') }
  }

  async function addTodo() {
    try {
      if (!formState.name) return
      const todo = { ...formState }
      //console.log(todo)
     
      await API.graphql(graphqlOperation(createTodo, {input: todo}))
      fetchTodos()
      setInput('name','')
    } catch (err) {
      console.log('error creating todo:', err)
    }
  }

  async function delTodo(todo) {
    try {
      await API.graphql(graphqlOperation(deleteTodo, {input: {id:todo.id}}))
      fetchTodos()
    } catch (err) {
      console.log('error deleting todo:', err)
    }
  }

  /*const handleSubmit = (event) => {
    event.preventDefault();
    //this.addTodo()
    // submit the form data
  };*/

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      addTodo()
    }
  };


  return (
    <div style={styles.container}>
      <Container  className="w-100 mb-2" >
        <Row >
          <Col xs={6} className="align-items-center"  ><h2 className="align-items-center">Sonntag-Ihle</h2></Col>
<Col xs={6} className="align-items-center">
<DropdownButton variant="info" className="float-end align-items-center" title={formState.category}>
      <Dropdown.Item onClick={()=>setInput('category', 'Einkaufen')}>Einkaufen </Dropdown.Item>
      <Dropdown.Item onClick={()=>setInput('category', 'Garten')}>Garten </Dropdown.Item>
      <Dropdown.Item onClick={()=>setInput('category', 'Urlaub')}>Urlaub </Dropdown.Item>
</DropdownButton></Col></Row>
      
      </Container>
      <Form >
        <Form.Group className="mb-1">
          <Form.Control autoFocus
            type="text"
            placeholder="hinzufügen"
            value={formState.name}
            onChange={(event) => setInput('name', event.target.value)}
            onKeyDown={handleKeyDown}
          />
        </Form.Group>
        <Button  className="mb-2 w-100" onClick={()=> addTodo()} variant="primary">hinzufügen</Button>
        </Form>
      <ListGroup>
        {todos.filter((todo)=>todo.category===formState.category).map((todo, index) => (
          <ListGroup.Item key={index}>
            <Button  variant="success" className='me-3' aria-label="Hide" onClick={() => delTodo(todo)} > x </Button>
            {todo.name}  
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  )
}

const styles = {
  container: { width: 400, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
  todo: {  marginBottom: 15 },
  input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
  todoName: { fontSize: 20, fontWeight: 'bold' },
  todoDescription: { marginBottom: 0 },
  button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}

export default App